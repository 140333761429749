<template>
  <asom-modal
    v-model="showItemList"
    title="Items"
    :dismissible="false"
    size="lg"
  >
    <div>
      <asom-client-table
        :columns="columns"
        :data="tableData"
        :filterable="false"
        :pagination="true"
      >
        <template v-slot:supplyDate="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:expiryDate="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:itemDescription="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
      </asom-client-table>
    </div>
    <div class="flex flex-row-reverse pt-4 gap-4">
      <asom-button
        @click="toggleModal(false)"
        text="Close"
        variant="secondary"
      />
    </div>
  </asom-modal>
</template>

<script>
import { mainPageItemTypes } from "../../constants/APIEnums/cashManagementEnums";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  props: {
    selectedSource: {
      type: undefined,
    },
    outOfStockInventoryList: {
      type: Array,
      default: () => [],
    },
    lowInventoriesList: {
      type: Array,
      default: () => [],
    },
    nearExpiryInventoriesList: {
      type: Array,
      default: () => [],
    },
    expiredInventoriesList: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    showItemList: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    tableData() {
      if (this.selectedSource == mainPageItemTypes.outOfStock.VALUE)
        return this.outOfStockInventoryList;
      else if (this.selectedSource == mainPageItemTypes.lowInventory.VALUE)
        return this.lowInventoriesList;
      else if (this.selectedSource == mainPageItemTypes.nearExpiry.VALUE)
        return this.nearExpiryInventoriesList;
      else if (this.selectedSource == mainPageItemTypes.expiredStock.VALUE)
        return this.expiredInventoriesList;
      else return [];
    },
    columns() {
      if (
        this.selectedSource == mainPageItemTypes.outOfStock.VALUE ||
        this.selectedSource == mainPageItemTypes.lowInventory.VALUE
      )
        return ["inventoryTypeName", "quantity"];
      else
        return [
          "inventoryTypeName",
          "amount",
          "primaryLocationName",
          "secondaryLocationName",
          "supplyDate",
          "expiryDate",
          "itemDescription",
        ];
    },
  },
  methods: {
    displayUtcDate,
    toggleModal(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
